.domestic-m-layout-root {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.domestic-m-footer-layout {
  margin-top: 24px;
}
