.form-root {
  width: 420px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #fff;
  box-shadow: 10px 30px 50px 0px;
}

.error {
  color: rgb(182, 32, 32);
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.form-header {
  width: 100%;
  height: 80px;
  border-radius: 20px 20px 0px 0px;
  border-bottom: 1px solid var(--Text-Colour-4, #aaa);
  background: var(--White, #fff);
  padding: 26px 40px;
  display: flex;
  justify-content: flex-start;
}

.form-header-text {
  color: #c07c3f;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.72px;
}

.form-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 50px;
  padding: 0px 40px;
}

.kutumb-form-field.ant-input {
  border-radius: 20px;
  height: 59px;
  background: rgba(212, 212, 212, 0.2);
}

.kutumb-form-field-search .ant-select-selector.ant-select-selector {
  border-radius: 20px;
  height: 59px;
  background: rgba(212, 212, 212, 0.2);
}

.form-button {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 40px 60px 40px;
  flex-direction: column;
  gap: 4px;
}

.kutumb-btn.ant-btn {
  border-radius: 50px;
  border: 1px solid #bfbdc1;
  height: 60px !important;
}

@media (width<=460px) {
  .form-root {
    width: 340px;
  }

  .form-header {
    padding: 10px 20px;
    height: auto;
  }
  .form-header-text {
    font-size: 24px;
  }

  .form-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 40px;
    padding: 0px 40px;
  }

  .form-button {
    margin-top: 50px;
  }
}
