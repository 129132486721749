.domestic-navbar-root {
  width: 100dvw;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-header-root {
  display: flex;
  width: inherit;
}

.nav-select-box {
  /* padding-right: 50px; */
}

.nav-header-checkout {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 1112px;
  justify-content: space-between;
}

.nav-header-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.nav-logo-checkout {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

@media (1060px < width < 1300px) {
  .nav-header-checkout {
    /* margin-left: 82px; */
    margin: 0px 82px;
    grid-template-columns: 2fr 1fr;
  }

  .nav-select-box {
    /* padding-right: 82px; */
  }
}

@media (720px < width <=1060px) {
  .nav-header-checkout {
    margin-left: 50px;
  }

  .nav-select-box {
    padding-right: 50px;
  }
}

@media (680px < width <=720px) {
  .nav-header-checkout {
    margin-left: 30px;
  }

  .nav-select-box {
    padding-right: 30px;
  }
}

@media (width <=680px) {
  .nav-header-checkout {
    margin-left: 16px;
  }

  .nav-select-box {
    padding-right: 16px;
  }

  .domestic-navbar-root {
    height: 56px;
  }
}