.navbar-root {
  width: 100dvw;
  min-height: 72px;
  display: flex;
  align-items: center;
  background-color: #c07c3f;
  justify-content: space-between;
  position: fixed;
  top: 0%;
}

.nav-header {
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0px 164px;
}

.kutumb-header-text {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.kutumb-nav-link-text {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.05px;
}

.nav-logo {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f1ff;
  border-radius: 100%;
}

.nav-links {
  display: flex;
  gap: 32px;
  margin: 0px 164px;
}

@media (1060px < width < 1300px) {
  .nav-header {
    margin: 0px 82px;
  }
  .nav-links {
    margin: 0px 82px;
  }
}

@media (720px < width <= 1060px) {
  .nav-header {
    margin: 0px 50px;
  }
  .nav-links {
    margin: 0px 50px;
  }
}

@media (680px < width <= 720px) {
  .nav-header {
    margin: 0px 30px;
  }
  .nav-links {
    margin: 0px 30px;
  }
}

@media (width <= 680px) {
  .navbar-root {
    padding-top: 15px;
  }
  .nav-header {
    margin: 0px 30px;
  }
  .navbar-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .nav-burger {
    height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    cursor: pointer;
    margin-right: 30px;
  }

  .nav-burger span {
    height: 4px;
    width: 30px;
    background-color: white;
  }

  .navbar-root {
    flex-direction: column;
    align-items: center;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;
    margin-left: 30px;
    padding: 20px 0px;
    gap: 20px;
  }
  .nav-links.open {
    display: flex;
  }
}
