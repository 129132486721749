.loadingMainDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 999;
  display: flex;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  align-items: center;
  justify-content: center;
  pointer-events: all;
}

.innerText {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.loadingText {
  margin-top: 24px;
  color: var(--Global-Neutral-Grey-1100, #2d2b32);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.loadingSubText {
  color: var(--Global-Neutral-Grey-1000, #4f4d55);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}