.home-root {
  display: flex;
  height: 100vh;
}

.privacy-root,
.tnc-root {
  margin-top: 290px;
  margin-right: 164px;
  margin-left: 164px;
}

.form-item {
  margin-left: auto;
  margin-top: 142px;
  margin-right: 164px;
}

@media (1060px < width < 1300px) {
  .privacy-root,
  .tnc-root {
    margin-right: 82px;
    margin-left: 82px;
  }
  .form-item {
    margin-right: 82px;
  }
}

@media (720px < width <= 1060px) {
  .privacy-root,
  .tnc-root {
    margin-right: 50px;
    margin-left: 50px;
  }
  .form-item {
    margin-right: 50px;
  }
}

@media (680px < width <= 720px) {
  .privacy-root,
  .tnc-root {
    margin-right: 50px;
    margin-left: 50px;
  }
  .form-item {
    margin-right: 50px;
  }
}

@media (width <= 680px) {
  .privacy-root,
  .tnc-root {
    margin: 80px 30px;
    margin-top: 142px;
  }
  .form-item {
    justify-content: center;
    align-items: center;

    padding: auto;
    margin: auto;
    margin-top: 142px;
  }
}

/* @media (720px < width<=900px) {
  .home-root {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-item {
    margin-top: 142px;
  }

  .privacy-root,
  .tnc-root {
    margin-top: 142px;
  }
}

@media (500px<width<=720px) {
  .form-item {
    margin: auto;
    padding: 0px;
    margin-top: 120px;
  }

  .privacy-root,
  .tnc-root {
    padding: 0px 40px;
    margin-top: 142px;
  }
}

@media (width<=500px) {
  .privacy-root,
  .tnc-root {
    padding: 0px 20px 80px 20px;
    margin: auto;
    margin-top: 122px;
  }

  .form-item {
    margin: auto;
    padding: 0px;
    margin-top: 120px;
  }
} */
