.upi-vpa-label {
  color: var(--character-title-85);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.05px;
}

@media (width <=680px) {
  .upi-vpa-label {
    font-size: 12px;
  }
}

.upi-qr-root {
  display: flex;
  column-gap: 20px;
}

.qr-code-btn {
  position: relative;
}

.qr {
  padding: 12px;
}

.generate-qr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.qr-desc {
  margin-top: 17px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.upi-app-img-section {
  display: flex;
  gap: 4px;
}

.upiQrPayButton {
  position: absolute;
  visibility: hidden;
  width: 0%;
}