.domestic-pi-selection-root {
}

.domestic-pi-item {
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
  cursor: pointer;
}

.domestic-pi-title-logo {
  display: flex;
  gap: 8px;
}

.domestic-pi-header {
  color: var(--character-title-85);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.05px;
}
