.tab-div-styling .ant-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  border-radius: 8px;
  border: 1px solid var(--Primary-6, #1890ff);
  background: var(--Primary-1, #e6f7ff);
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.tab-div-styling .ant-tabs-tab .ant-tabs-tab-btn {
  border-radius: 8px;
  border: 1px solid var(--Neutral-4, #f0f0f0);
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
}
.tab-div-styling .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 10px;
}

.tab-div-styling .ant-tabs .ant-tabs-tab:hover {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 0px;
}
.tab-div-styling .ant-tabs .ant-tabs-tab {
  padding: 0px;
}

.tab-div-styling
  .ant-tabs
  .ant-tabs-tab
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  border-bottom: none;
}

.tab-div-styling .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  visibility: hidden;
  display: none !important;
}

.tab-div-styling .ant-tabs-ink-bar {
  height: 5px;
  background: transparent !important;
}

/* emi methods styling */
.bankSelect .ant-select-single {
  width: 100%;
}

.emiOptionHeading {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.emiOptionSubHeading {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.amountCurrency {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  padding-right: 2px;
}

.amountPerAnum {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.forwardIcon {
  width: 20px;
  height: 20px;
  color: rgba(127, 125, 131, 1);
  padding-left: 8px;
}

.bank-logo {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

/* emi plans */

.selectEmiText {
  color: var(--Global-Neutral-Grey-1000, #4f4d55);

  /* Regular/14 */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 20px;
}

.planDetailsText {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 0px 0px 0px 4px;
}

.emiDetailsMargin {
  margin-left: auto;
}

.emiDetailsHeading {
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));

  /* Regular/12 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.noteHeading {
  color: var(--Global-Neutral-Grey-1000, #4f4d55);

  /* Regular/12 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.detailsStyling {
  display: flex;
  margin-top: 8px;
}

@media (width <=680px) {
  .tab-div-styling
    .ant-tabs
    .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    display: flex;
    padding: 2px 6px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid var(--Primary-6, #1890ff);
    background: var(--Primary-1, #e6f7ff);
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .ant-tabs .ant-tabs-tab-btn {
    color: var(--Global-Neutral-Grey-900, #7f7d83);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
}

.no-cost-emi-available {
  display: flex;
  height: 20px;
  padding: 1px 8px;
  align-items: center;
  gap: 3px;
  border-radius: 4px;
  border: 1px solid var(--Primary-3, #91d5ff);
  background: var(--Primary-1, #e6f7ff);
}
.no-cost-emi {
  display: flex;
  width: 140px;
  height: 20px;
  padding: 1px 8px;
  align-items: center;
  gap: 3px;
  border-radius: 4px;
  border: 1px solid var(--Primary-3, #91d5ff);
  background: var(--Primary-1, #e6f7ff);
}

.no-cost-text {
  color: var(--Primary-6, #1890ff);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
@media (max-width: 1170px) and (min-width: 1060px) {
  .overflowBankText {
    width: 130px;
    max-width: 500px;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
