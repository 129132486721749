.domestic-bottom-footer-root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0px;
}

.logo-section {
  display: flex;
}

.boxpay-section {
  display: flex;
  align-items: center;
  gap: 8px;
}

.boxpay-section-mobile {
  display: none;
}

.boxpay-branding {
  display: flex;
  align-items: center;
  gap: 4px;
}

.footer-boxpay-front {
  font-weight: 500;
  font-style: italic;
  font-size: 12px;
  line-height: 18px;
  color: var(--Neutral-Grey-900);
}

.footer-boxpay-back {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: var(--Neutral-Grey-1300);
}

.other-logo-section {
  display: flex;
  gap: 16px;
}

.footer-content-container {
  height: 40px;
  width: 58px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.boxpay-logo-divider {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
  margin-right: 12px;
}

.help-section {
  color: var(--Neutral-Grey-1000);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.help-section-anchor {
  color: var(--Primary-8);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;
  padding-left: 0.3rem;
}

@media (width <=900px) {
  .domestic-bottom-footer-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 58px;
  }

  .other-logo-section img {
    width: 40px;
    height: 28px;
  }

  .footer-content-container {
    width: 40px;
    height: 28px;
  }

  .logo-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    align-items: center;
  }

  .boxpay-logo-divider {
    display: none;
  }

  .boxpay-section {
    display: none;
  }

  .boxpay-section-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
  }

  .boxpay-branding {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .boxpay-branding img {
    height: 9px;
    width: 8px;
  }

  .footer-boxpay-front {
    font-weight: 500;
    font-style: italic;
    font-size: 10px;
    line-height: 18px;
    color: var(--Neutral-Grey-900);
  }

  .footer-boxpay-back {
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: var(--Neutral-Grey-1300);
  }

  .other-logo-section {
    display: flex;
    gap: 12px;
  }

  .help-section {
    margin-top: 20px;
  }
}