.domestic-s-root {
  width: 450px;
  background-color: #fff;
  padding: 25px 0px;
  overflow-x: hidden;
  border-radius: 12px;
  margin: auto;
}

.domestic-s-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}

.domestic-s-divider-root {
  align-items: center;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.domestic-s-divider {
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  border: 1px dashed #dcdee0;
}

.domestic-s-divider-2 {
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  border: 1px dashed #dcdee0;
  margin: 4px 0px;
}

.domestic-s-cut-1 {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background-color: #f9f9f9;
  transform: translate(-10px, 0px);
}

.domestic-s-cut-2 {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background-color: #f9f9f9;
  transform: translate(10px, 0px);
}

.domestic-s-summary {
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  gap: 12px;
}

.domestic-s-summary-item {
  display: flex;
  justify-content: space-between;
}

/* .domestic-s-summary-item>* {
  width: auto;
} */

.domestic-s-summary-item-title {
  color: #707070;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.domestic-s-summary-margin {
  margin-bottom: 1rem;
}

.domestic-s-bold-text {
  font-weight: 600 !important;
}

.domestic-s-summary-item-value {
  color: #121212;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.domestic-s-footer-divider {
  margin: 12px;
  color: #e8eaed;
}

.domestic-s-footer {
  text-align: center;
  padding: 0px 24px;
  color: var(--Neutral-Grey-1000);
}

.domestic-s-amount {
  color: #121212;

  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.oppsHeading {
  color: #000;
  text-align: center;
  margin-top: 32px;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.oppsSubHeading {
  color: var(--Global-Neutral-Grey-1000, #4f4d55);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 8px;
}

.domestic-s-merchant-site-link {
  text-decoration: underline !important;
  padding-left: 0.3rem !important;
}

.currency-span {
  padding: 0 0.2rem;
}

.product-item-container {
  display: grid;
  gap: 0.4rem;
  justify-items: flex-end
}

.view-text {
  font-weight: 600;
  color: blue;
  cursor: pointer;
  display: flex;
  align-items: center;
}