.currency-input {
  width: 100%;
  position: relative;
}

.currency-input-wrapper {
  padding: 10px;
  margin-bottom: 20px;
  min-height: 150px;
}

.currency-symbol-onDemand {
  margin-right: 5px;
  font-size: 48px;
  color: #000;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: sans-serif !important;
}


.currency-input input {
  text-align: center;
  border: none;
  outline: none;
  font-size: 60px;
  width: 100%;
  font-weight: 400;
  font-family: "Poppins";
}

.currency-input input::placeholder {
  color: #aaa;
}

.amount-in-words {
  font-size: 14px;
  color: #888;
  text-align: center;
  margin-top: -10px;
}

@media only screen and (max-width: 1400px) {
  .currency-input input {
    font-size: 50px;
  }
}

@media only screen and (max-width: 1400px) {
  .currency-input input {
    font-size: 45px;
  }
}

@media only screen and (max-width: 768px) {
  .currency-input input {
    font-size: 40px;
  }

  .currency-input-wrapper {
    padding-top: 0;
  }

  .currency-symbol-onDemand {
    font-size: 36px;
  }
}