body,
html {
  overflow-x: hidden;
}

.domestic-layout-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.domestic-layout-success {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100dvw;
  height: 100dvh;
}

.domestic-layout-content {
  margin: 0px 164px;
  display: grid;
  grid-template-columns: 672px 366px;
  column-gap: 74px;
  padding-top: 16px;
}
.review-layout-content {
  margin: 0px 164px;
  display: grid;
  grid-template-columns: 672px 366px;
  column-gap: 74px;
  padding-top: 24px;
}
.domestic-layout-footer {
  margin: 0px 164px;
  width: 1110px;
}

.domestic-layout-left-side {
  display: flex;
  flex-direction: column;
}

.domestic-layout-left-side-header {
  display: flex;
  justify-content: space-between;
}

.domestic-layout-right-side {
  display: flex;
  flex-direction: column;
}

.review-pay-container {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}

.review-pay {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.4px;
}

.domestic-layout-left-side-header-logout {
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
}

.logout-font {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.18px;
}

.emptyCard .ant-card .ant-card-body {
  padding: 16px;
}

.emptyCardHeading {
  display: flex;
  align-items: center;
  gap: 4px;
}

.emptyCardHeading {
  color: var(--Global-Neutral-Grey-900, #7f7d83);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.emptyCardIcon {
  color: rgba(127, 125, 131, 1);
}

.emptyCard .ant-card .ant-card-body {
  padding: 16px 24px;
}

@media (1060px < width < 1300px) {
  .domestic-layout-root {
    align-items: normal;
  }
  .domestic-layout-content {
    margin: 0px 82px;
    grid-template-columns: 2fr 1fr;
  }
  .review-layout-content {
    margin: 0px 82px;
    grid-template-columns: 2fr 1fr;
  }
  .domestic-layout-footer {
    margin: 0px 82px;
    width: auto;
  }
}

@media (720px < width <= 1060px) {
  .domestic-layout-root {
    align-items: normal;
  }

  .domestic-layout-content {
    margin: 0px 50px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .review-layout-content {
    margin: 0px 50px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .domestic-layout-footer {
    margin: 0px 50px;
    width: auto;
  }

  .domestic-layout-right-side {
    order: 1;
  }

  .domestic-layout-left-side {
    order: 2;
  }
}

@media (680px < width <= 720px) {
  .domestic-layout-root {
    align-items: normal;
  }
  .domestic-layout-footer {
    margin: 0px 30px;
    width: auto;
  }

  .domestic-layout-content {
    margin: 0px 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .review-layout-content {
    margin: 0px 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .domestic-layout-right-side {
    order: 1;
  }

  .domestic-layout-left-side {
    order: 2;
  }
}
