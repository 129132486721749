.domestic-po-root {
  border: 1px solid var(--Neutral-4);
  border-radius: 12px;
  box-shadow: 0px 5px 13px -5px rgba(10, 9, 11, 0.05),
    0px 2px 4px -1px rgba(10, 9, 11, 0.02);
  background-color: #ffffff;
  display: grid;
  grid-template-columns: minmax(190px, 248px) 1fr;
  min-height: 620px;
}

.domestic-po-header-tabs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid var(--Neutral-4);
  max-width: 248px;
}

.domestic-po-header {
  padding: 12px 24px;
  width: 100%;
  border-bottom: 1px solid var(--Neutral-4, #F0F0F0);
  display: flex;
  align-items: center;
  gap: 4px;
}

.domestic-po-tab {
  padding: 28px 24px;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid var(--Neutral-4, #F0F0F0);
}

.domestic-po-content {
  min-width: 348px;
  padding: 30px 40px;
}

.tab-title-heading {
  color: var(--Neutral-Grey-1000);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.18px;
}

.tab-title-heading-active {
  color: var(--Neutral-Grey-1200);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.18px;
}

.domestic-card-mb-header {
  color: var(--Neutral-Grey-1000);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.05px;
}

.domestic-empty-po-root {
  border: 1px solid var(--Neutral-4);
  border-radius: 12px;
  box-shadow: 0px 5px 13px -5px rgba(10, 9, 11, 0.05),
    0px 2px 4px -1px rgba(10, 9, 11, 0.02);
  background-color: #ffffff;
}

.no-payment-options-div {
 text-align: center;
 padding: 30% 10%;
}
.no-payment-options-Heading {
  color: var(--Global-Neutral-Grey-1000, #4F4D55);
      font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    padding-top: 16px;
}
.no-payment-options-subHeading {
color: var(--Global-Neutral-Grey-800, #ADACB0);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding-top: 4px;
}

@media (width <= 680px) {
  .domestic-saved-instruments {
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
  }
  .domestic-saved-instruments-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
}
