.or-root {
  display: grid;
  grid-template-columns: 2.5fr 1fr 2.5fr;
  align-items: center;
}
.or-left-divider {
  height: 0px;
  border: 1px solid var(--Conditional-divider);
}
.or-left-right {
  height: 0px;
  border: 1px solid var(--Conditional-divider);
}
