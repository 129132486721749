.onDemandCard {
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    width: 100%;
    margin: 0px auto;
    overflow-y: scroll;
    gap: 20px;
    border: 1px solid #F0F0F0;
    box-shadow: 0px 5px 13px -5px rgba(10, 9, 11, 0.05), 0px 2px 4px -1px rgba(10, 9, 11, 0.02);
    height: 90%;
    z-index: 1;
    position: relative;
    max-width: 1112px;
}

.backgroundImgDemand {
    position: absolute;
    z-index: 0;
    right: 0;
    top: 0;
    width: 1000px;
}

.onDemandDescription {
    height: 150px;
    overflow-y: scroll;
}

.onDemandFooter {
    width: 100%;
    background: #fff;
    padding: 0 40px;
    margin: 0;
    z-index: 1;
}

.onDemandCardContainer {
    height: 80%;
    width: 100vw;
    padding: 0px 164px;
    ;
}

.onDemandContainer {
    width: 100%;
    height: calc(100vh - 72px);
    padding-top: 60px;
    background: #F9F9F9;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url("../../../assets/on_demand_background.svg");
    background-size: cover;
    background-position: center right;

}

.onDemand-heading {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 40px;
    text-transform: uppercase;
    color: #00000073;
}

.onDemand-subheading {
    color: #00000073;
}

.descriptionContainer {}

.onDemandCard .leftCard {
    width: 55%;
    padding: 60px 40px;
    padding-bottom: 0px;
    overflow-y: scroll;
}

.onDemandCard .rightCard {
    width: 45%;
    text-align: center;
    border-left: 1px solid #F0F0F0;
    padding: 60px 40px;
}

@media (1060px < width < 1300px) {
    .onDemandCardContainer {
        padding: 0px 82px;
    }
}

@media (720px < width <=1060px) {
    .onDemandCardContainer {
        padding: 0px 50px
    }
}

@media only screen and (max-width: 768px) {
    .onDemandContainer {
        padding-top: 10px;
        background-image: none;
        justify-content: flex-start;
        gap: 20;
    }

    .onDemandCardContainer {
        padding: 0 !important;
    }




    .onDemandCard {
        justify-content: space-between;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 5px 13px -5px #0a090b0d;
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: 20px auto;
        padding: 30px 20px;
        max-height: 80vh;
    }

    .onDemand-heading {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        text-align: left;
    }

    .onDemandCard .semiBold26Heading {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        text-align: left;
    }

    .onDemand-subheading {
        font-family: Poppins, sans-serif;
        font-size: 12px;
        line-height: 1.3;
        color: #00000073;
    }

    .onDemandCard .leftCard {
        width: 100%;
        padding: 0;
        height: 55%;
    }

    .showElipsis {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .onDemandCard .rightCard {
        width: 100%;
        text-align: center;
        padding: 0;
        border: none;
        height: 45%;
    }

    .onDemandPaymentButtonContainer {
        /* position: absolute;
        bottom: 30px; */
        width: 100%;
        /* left: 0; */
    }

    .onDemandPaymentButtonContainer button {
        width: 90% !important;
        margin: 0 auto;
    }
}