.domestic-error-root {
  padding: 24px;
}

.domestic-error-header-conatiner {
  display: flex;
  gap: 8px;
}

.domestic-error-header-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.domestic-error-header-title {
  color: var(--character-title-85);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.18px;
}

.domestic-error-header-desc {
  color: var(--character-primary-85);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.05px;
}

.domestic-error-footer {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
