.data-container-root {
  display: flex;
  width: 100%;
  min-height: 428px;
  flex-direction: column;
  padding: 40px;
  align-items: center;
  gap: 40px;
  background: #fff;
  box-shadow: 0px 2px 20px 0px rgba(22, 22, 22, 0.1);
}
.data-container-title {
  color: #000;
  font-family: Poppins;
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 78.571% */
}

.data-container-heading {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: 40px; /* 78.571% */
}

.description {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 211.111% */
  letter-spacing: -0.26px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (720px<= width <=900px) {
  .data-container-root {
    padding: 20px;
  }
  .data-container-title {
    font-size: 32px;
  }
  .data-container-root {
    gap: 20px;
  }
}

@media (width<720px) {
  .data-container-root {
    padding: 20px;
  }
  .data-container-title {
    font-size: 32px;
  }
  .data-container-root {
    gap: 20px;
  }
}
