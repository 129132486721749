.errorMainDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: calc(100vh - 72px);
}

.errormainHeading {
  color: #000;
  text-align: center;
  margin-top: 24px;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.errorSubHeading {
  color: var(--Global-Neutral-Grey-1000, #4f4d55);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
