.dcc-quotation-main {
    margin-top: 0.6rem;
}

.dcc-quotation-box {
    display: flex;
}

.dcc-quotation-header {
    margin-bottom: 1rem;
}

.dcc-quotation-flag-box {
    display: flex;
    align-items: center;
}

.dcc-quotation-flag {
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
}

.dcc-quotation-amount-text {
    padding-left: 0.4rem;
}

.dcc-quotation-container {
    display: flex;
    gap: 1rem
}

.dcc-quotation-container-1 {
    display: grid;
    gap: 1rem
}

.dcc-quotation-radio-box {
    display: grid;
    gap: 1.3rem;
    align-items: baseline;
    /* height: 100%; */
    grid-template-rows: 50% 50%;
}

.dcc-quotation-antd-radio {
    align-items: center;
}

.dcc-quotation-details {
    display: grid;
    gap: 1.3rem;
    grid-template-rows: 50% 50%;
}

.dcc-quotation-disclaimer {
    padding-top: 2rem;
    font-size: 0.7rem;
}

.dcc-quotation-section {
    display: grid;
    /* gap: 1rem; */
    grid-template-columns: 30% 70%;
    align-items: flex-start;
}

.dcc-quotation-radio-details {
    padding-left: 1rem;
}