.orderSummaryCard {
  border-radius: 0px 0px 12px 12px;
  background: #fff;
  padding: 16px 0px;
  margin-bottom: 24px;
}

.displayFlex {
  display: flex;
}

.orderSummaryTitle {
  color: var(--Global-Purple-900, #403e9f);
}

.marginTop12 {
  margin-top: 12px;
}

.itemTitle {
  color: var(--Global-Neutral-Grey-1100, #2d2b32);
}

.currencySymbol {
  font-family: sans-serif;
  color: var(--Global-Neutral-Grey-1300, #0a090b);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.mainOrderDiv {
  display: flex;
  justify-content: space-between;
}

.itemAmount {
  color: var(--Global-Neutral-Grey-1300, #0a090b);
}

.orderItemHeading {
  color: var(--Global-Neutral-Grey-900, #7f7d83);
}

.subHeadingValue {
  color: var(--Global-Neutral-Grey-1000, #4f4d55);
}

/* scroll styling */
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 6px;
}

.orderSummaryScroll {
  max-height: 300px;
  overflow-y: auto;
  padding: 0px 16px;
}

.divider {
  margin: 12px 0px 16px 0px;
}

.quantityStyling {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--Global-Neutral-Grey-300, #f1f1f1);
  width: 20px;
  text-align: center;
  border-radius: 50%;
  margin-right: 4px;
  margin-top: -4px;
  color: var(--Global-Orange-1100, #762a0e);
}

.itemImage {
  margin-right: 12px;
  height: 60px;
  width: 60px;
  border-radius: 8px;
  border: 2px solid rgba(220, 220, 222, 1);
  object-fit: cover;
}

.imageAndQuantityDiv {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.informationColor {
  color: var(--Global-Neutral-Grey-1000, #4f4d55);
}

.payment-link-productTitle {
  color: var(--Global-Neutral-Grey-1200, #1d1c20);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding-bottom: 9px;
}

.payment-link-productName {
  color: var(--Global-Neutral-Grey-1000, #4f4d55);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 9px;
}

.payment-link-amount {
  color: var(--Global-Neutral-Grey-1300, #0a090b);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding-bottom: 9px;
}

.payment-link-description {
  color: var(--Text-Medium, rgba(1, 1, 2, 0.6));
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}


@media screen and (min-width: 1061px) {
  .payment-link-title {
    width: 260px;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .payment-link-orderid {
    width: 200px;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (width <=680px) {
  .orderSummaryCard {
    padding: 0px;
  }

  .orderSummaryCard {
    border: none;
    padding: 0px;
    margin-bottom: 0px;
  }

  .orderSummaryScroll {
    max-height: 140px;
    padding: 0px 16px 16px 16px;
  }

  .itemImage {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    border: 1.5px solid var(--Neutral-Grey-600);
  }

  .itemTitle {
    font-weight: 500;
    color: var(--Neutral-Grey-1000);
  }

  .price {
    font-size: 14px;
  }

  .orderItemHeading,
  .subHeadingValue {
    font-size: 12px;
  }

  .divider {
    margin: 8px 0px;
  }

  .payment-link-title {
    width: 250px;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .payment-link-orderid {
    width: 200px;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}