:root {
  --Neutral-Grey-600: #dcdcde;
  --Neutral-Grey-900: rgba(127, 125, 131, 1);
  --Neutral-Grey-1000: #4f4d55;
  --Neutral-Grey-1100: #2d2b32;
  --Neutral-Grey-1200: #1d1c20;
  --Neutral-Grey-1300: rgba(10, 9, 11, 1);
  --Neutral-4: #f0f0f0;
  --Neutral-2: #fafafa;
  --character-title-85: rgba(0, 0, 0, 0.85);
  --Red-700: #f53535;
  --Red-800: #e12121;
  --Red-900: #bc1c1c;
  --Primary-6: #1890ff;
  --Primary-7: #096dd9;
  --Primary-8: #403E9F;
  --Orange-200: rgba(255, 235, 226, 1);
  --Conditional-divider: rgba(0, 0, 0, 0.06);
}

body {
  background-color: #f9f9f9;
}

.ant-typography,
.ant-input-group-addon,
.ant-input {
  font-family: "Poppins";
}

.ant-card {
  border-radius: 12px;
  border: 1px solid var(--Neutral-4);
  background: #fff;
}

.ant-btn {
  height: auto !important;
}

.ant-modal-content {
  padding: 0px !important;
}

.ant-collapse {
  border-radius: 12px;
  border: 1px solid var(--Neutral-4);
  background: #fff;
}

.ant-collapse-header {
  display: flex !important;
  align-items: center !important;
  flex-direction: row;
}

.ant-collapse-header :nth-child(3) {
  order: 3;
}

.ant-collapse-header-text {
  display: contents;
}

.ant-collapse-extra {
  margin-left: auto;
}

.ant-collapse-content {
  border: none !important;
}

.ant-collapse-item {
  border: none !important;
}

.ant-collapse-content-box {
  padding: 0px !important;
  border: none;
}

.ant-input-group-addon {
  color: #000 !important;
}

.ant-form-item-explain-error {
  color: var(--Red-800);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.ant-input {
  height: 40px;
}

.ant-picker-outlined {
  height: 40px;
}

.ant-select-single {
  height: 40px;
}

.ant-divider {
  margin: 0px;
}

.domestic-layout-content-footer-divider {
  margin-top: 32px;
}

.currency-symbol {
  font-family: sans-serif !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ant-drawer-content-wrapper {
  height: unset !important;
}

.ant-drawer-content-wrapper,
.ant-drawer-content {
  border-radius: 12px 12px 0px 0px;
  background: var(--Neutral-1, #fff);
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
}

.ant-drawer-body {
  padding: 12px 16px !important;
  min-height: fit-content !important;
  max-height: 80vh !important;
}

@media (width <=680px) {
  .ant-card-head {
    padding: 12px 16px !important;
    height: auto !important;
    min-height: auto !important;
  }

  .ant-card-body {
    padding: 12px 16px !important;
  }
}