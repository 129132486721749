.subscriptionTitle {
  color: var(--Neutral-Grey-1100);
}
.subscriptionDescription {
  color: var(--Neutral-Grey-1000);
}

.subscriptionMarginBottom {
  margin-bottom: 8px;
}
.subscriptionMarginTop {
  margin-top: 8px;
}

.subscriptionDetails {
  display: flex;
  justify-content: space-between;
}

.itemNameEllipse {
  width: 250px;
  max-width: 500px;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.marginLeft {
    margin-left: 4px;
}

.subscriptionAmountCurrency {
  color: var(--Global-Neutral-Grey-1200, #1D1C20);
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
}
.subscriptionAmount {
  color: var(--Global-Neutral-Grey-1200, #1D1C20);
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
}

.productItemNameEllipse {
  width: 100px;
  max-width: 500px;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}