.domestic-login-header {
  display: flex;
  gap: 4px;
}

.domestic-login-card.ant-card {}

.domestic-login-content {
  max-width: 330px;
}

.shopper-login-mobile.ant-input-number-group-wrapper {
  width: 100%;
}

.shopper-login-mobile .ant-input-number .ant-input-number-handler-wrap {
  display: none !important;
}

.domestic-login-header-title {
  color: var(--character-title-85);
}

.domestic-shopper-login-tnc {
  color: var(--Neutral-Grey-900);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.domestic-shopper-login-tnc a {
  color: var(--Neutral-Grey-1100);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-decoration: none;
  text-decoration-line: underline;
}

.domestic-shopper-login-tnc a:hover {
  color: var(--Neutral-Grey-1100);
  text-decoration-line: underline;
}

.shopper-login-code-label {
  color: var(--Neutral-Grey-1000);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.05px;
}

.shopper-login-code-ph {
  color: var(--Neutral-Grey-1200);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02px;
}

.resend-code-inactive {
  color: var(--Neutral-Grey-900);
}

.resend-code-timer {
  color: var(--Red-800);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.resend-code-active {
  color: var(--Primary-6);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
}

.shopper-loggin-resend-code-error {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.shopper-loggin-resend-code-error {
  margin-left: 0;
  margin-right: auto;
}

.shopper-login-resend-code {
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;
  text-wrap: nowrap;
}

.shopper-login-otp-errors {
  width: 200px;
}

.domestic-mobile-login-card.ant-card-body {
  padding: 0px;
}

.guest-Heading {
  color: var(--Global-Neutral-Grey-900, #7f7d83);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 8px 0px 24px 0px;
}

@media (width<=680px) {
  .domestic-login-content {
    max-width: unset;
  }

  .domestic-shopper-login-tnc {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }

  .domestic-shopper-login-tnc a {
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-decoration-line: underline;
  }

  .shopper-login-otp-errors {
    width: 150px;
  }
}

.verticalDivider {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkboxText {
  color: var(--Global-Neutral-Grey-1000, #4f4d55);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0px 0px 12px 0px;
}

@media screen and (min-width: 680px) {
  .guestverticalDivider {
    display: block;
  }

  .guestHorizontalDivider {
    display: none;
  }

  .login-flex {
    display: grid;
    grid-template-columns: 1fr 0.3fr 1fr;
  }

  /* .login-flex .ant-form-item {
    margin-bottom: 0px;
  } */

  .loginInputWidth {
    width: 100%;
  }

  .loginDividerWidth {
    width: 100%;
  }

  .verticalDivider .ant-divider {
    height: 38px;
  }
}

@media (max-width: 680px) and (min-width: 300px) {
  .guestverticalDivider {
    display: none;
  }

  .guestHorizontalDivider {
    display: block;
  }

  .guestHorizontalDivider .ant-divider {
    margin: 8px 0px 2px 0px;
    color: rgba(135, 134, 134, 1);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}