.headerDiv {
  display: flex;
  gap: 4px;
  align-items: center;
}

.deliveryAddressCard {
  border-radius: 12px;
  background: #fff;
}

.deliveryTitle {
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
}

.textWrapSingleLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.addressDivider .ant-divider {
  background-color: transparent;
  margin: 0px;
  padding-bottom: 12px;
  padding-top: 16px;
  padding: 16px 24px 0px 24px;
}

.deliveryForm .ant-card-head {
  padding: 16px 24px;
}

.deliveryForm .ant-card-body {
  padding: 0px;
}

.deliveryForm .ant-form-item {
  margin-bottom: 20px;
}

.deliveryAddressHeader {
  border-radius: 12px 12px 0px 0px;
  border: 1px solid var(--Neutral-4, #f0f0f0);
  background: var(--Neutral-1, #fff);
  justify-content: space-between;
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 36px;
  align-self: stretch;
}

.mainAddressDiv {
  background: var(--Neutral-1, #fff);
  border-radius: 0px 0px 12px 12px;
}

.savedAddressScroll {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 300px;
  padding: 0px 24px 8px 24px;
}

.addressSavedDivider .ant-divider {
  background-color: transparent;
  margin: 0px;
  padding-bottom: 12px;
  padding-top: 16px;
  padding: 16px 24px 0px 24px;
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (min-width: 1060px) {
  .deliveryAddressFields {
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 22px;
  }

  .savedAddressFields {
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 24px;
  }

  .cardPadding {
    padding: 0px 24px 20px 24px;
  }

  .deeliveryHeading {
    color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .truck {
    width: 20px;
    height: 20px;
  }

  .add-new-address {
    /* Regular/14 */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: var(--Global-Neutral-Grey-1300, #0a090b);
    margin-left: 4px;
  }

  .collapseAddressDiv {
    background-color: white;
    padding: 16px 24px 20px 24px;
    border-radius: 0px 0px 12px 12px;
  }

  .collapseAddressData {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.05px;
  }

  .savedAddressTypeDiv {
    margin-left: 8px;
    display: flex;
    padding: 0px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    border: 1px solid var(--Neutral-4, #f0f0f0);
    background: var(--Primary-1, #e6f7ff);
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .shopperAddressDiv {
    gap: 30px;
  }

  .Margin4Right {
    margin-right: 4px;
  }

  .Margin4Left {
    margin-left: 4px;
  }

  .addressDivider .ant-divider {
    background-color: transparent;
    margin: 0px;
    padding-bottom: 12px;
    padding-top: 16px;
    padding: 16px 24px;
    color: var(--character-primary-85, rgba(0, 0, 0, 0.85));

    /* Regular/14 */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
}

@media (max-width: 1060px) and (min-width: 300px) {
  .savedAddressFields {
    padding: 0px 16px 20px 16px;
    display: block;
  }

  .cardPadding {
    padding: 0px 16px 20px 16px;
  }

  .deliveryAddressHeader {
    gap: 0px;
  }

  .deeliveryHeading {
    color: var(--Global-Neutral-Grey-1000, #4f4d55);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .truck {
    width: 18px;
    height: 18px;
    text-align: center;
  }

  .add-new-address {
    color: var(--Global-Neutral-Grey-1300, #0a090b);
    margin-left: 4px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .collapseAddressDiv {
    background-color: white;
    padding: 12px 16px;
    border-radius: 0px 0px 12px 12px;
  }

  .collapseAddressData {
    color: var(--Global-Neutral-Grey-1100, #2d2b32);

    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .savedAddressTypeDiv {
    margin-left: 4px;
    display: flex;
    padding: 0px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    border: 1px solid var(--Neutral-4, #f0f0f0);
    background: var(--Primary-1, #e6f7ff);
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
  }

  .addressDivider .ant-divider {
    background-color: transparent;
    margin: 0px;
    color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
}

.continueButtonDiv {
  display: flex;
  padding: 16px 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-top: 1px solid var(--Neutral-4, #f0f0f0);
  box-shadow: 0px -2px 8px 0px rgba(71, 71, 71, 0.06);
  margin-top: 12px;
}

.continueButtonDiv .ant-btn.ant-btn-block {
  width: 300px;
}

.savedAddressCard {
  padding: 12px;
  margin-top: 16px;
  height: 110px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid var(--Neutral-4, #f0f0f0);
  background: var(--Neutral-1, #fff);
}

.overflowText {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.contactNumber.ant-select.ant-select-in-form-item {
  width: 110px;
}

.contactNumber.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  background: var(--Neutral-2, #fafafa);
}

.ant-space-compact {
  width: 100%;
}

.selectedCard {
  border: 1px solid var(--Primary-6, #1890ff);
}

.shopperAddressDiv {
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  background: var(--Neutral-2, #fafafa);
  padding: 8px 12px;
}

.moreOption {
  color: rgba(0, 0, 0, 0.85);
}

.savedAddressDiv {
  display: flex;
  /* gap: 40px; */
  justify-content: space-between;
}

.iconMarginRight {
  margin-right: 8px;
}

.editIcon {
  cursor: pointer;
}

.addNewAddress {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.savedAddresTitle {
  display: flex;
  padding: 6px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  margin-right: 12px;
  margin-top: 8px;
}

.unselectedAddress {
  border: 1px solid var(--Neutral-4, #f0f0f0);
  background: var(--Global-Neutral-Grey-300, #f1f1f1);
  cursor: pointer;
}

.selectedSavedAddress {
  border: 1px solid var(--Neutral-4, #f0f0f0) !important;
  background: var(--Primary-1, #e6f7ff);
  cursor: pointer;
}

.disabledAddressField {
  border-radius: 8px;
  border: 1px solid var(--Neutral-5, #d9d9d9);
  background: var(--Global-Neutral-Grey-300, #f1f1f1);
  color: var(--character-disabled-placeholder-25, rgba(0, 0, 0, 0.25));
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.date-picker-w-100 {
  width: 100%;
}