.medium20Heading {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.semiBold16Text {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.18px;
}

.semiBold16Heading {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.semiBold18Heading {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.semiBold14Heading {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.semiBold12Heading {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.semiBold24Heading {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.72px;
}
.semiBold26Heading {
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.72px;
}

.medium14Heading {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.medium16Heading {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.18px;
}

.regular16Heading {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.05px;
}

.regular14Heading {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.05px;
}

.medium12Heading {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
.regular12Heading {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.extraSmallBold {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.regular10Heading {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
.regular48Heading {
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
}
