.domestic-vsi-root {
}

.domestic-vsi-header-root {
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
}
.domestic-vsi-header-conatiner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
}
.domestic-vsi-header {
  display: flex;
  gap: 4px;
  align-items: center;
}
.domestic-vsi-content {
  padding: 16px 24px;
}

.domestic-vsi-footer {
  box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
}

.domestic-saved-instruments-root {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.domestic-saved-instruments-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.domestic-saved-instruments-view-all-logo {
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
}
.domestic-saved-instruments-view-all-title {
  color: var(--Primary-6);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
