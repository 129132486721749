.domestic-payment-summary-root {
  display: flex;
  flex-direction: column;

  padding: 16px;
  border-radius: 0px 0px 12px 12px;
  background: #fff;
  box-shadow: 0px 5px 13px -5px rgba(10, 9, 11, 0.05),
    0px 2px 4px -1px rgba(10, 9, 11, 0.02);
    /* margin-top: 16px; */
}

.payment-summary-desc {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.payment-summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-summary-total {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.payment-summary-item-title {
  color: var(--Neutral-Grey-900);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.05px;
}

.payment-summary-item-value {
  color: var(--Neutral-Grey-1200);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.05px;
}

.payment-summary-total-title {
  color: var(--Neutral-Grey-900);
}

.payment-summary-total-value {
  color: var(--Neutral-Grey-1300);
}

.domestic-ps-mobile-header {
  color: var(--Global-Neutral-Grey-1000);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.18px;
}

@media (width <= 680px) {
  .domestic-payment-summary-root {
    padding-top: 0px;
  }

  .payment-summary-desc {
    gap: 4px;
  }
  .payment-summary-total {
    margin-top: 12px;
  }

  .payment-summary-item-title {
    font-size: 12px;
  }

  .payment-summary-item-value {
    font-size: 12px;
  }

  .payment-summary-total-title {
    color: var(--Neutral-Grey-900);
  }

  .payment-summary-total-value {
    color: var(--Neutral-Grey-1300);
  }

  .semiBold18Heading {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.18px;
  }
}
