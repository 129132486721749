.domestic-mbtn-root {
  /* display: flex; */
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid var(--Neutral-4, #f0f0f0);
  background: var(--Neutral-1, #fff);
  box-shadow: 0px -2px 8px 0px rgba(71, 71, 71, 0.06);
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 999;
}

.convenience-fee-msz {
  font-size: 9px;
  text-align: left;
  font-weight: 500;
  margin-bottom: .35rem;
  color: var(--Neutral-Grey-900);
  /* color: rgb(185, 35, 47); */
}

@media (width <=680px) {
  .convenience-fee-msz {
    font-size: 12px;
  }
}