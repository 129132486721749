.payment-nav-root.ant-collapse {
  background-color: #fafafa;
  border: none;
}

.payment-nav-root .ant-collapse-expand-icon {
  margin-left: auto;
}
.payment-nav-root .ant-collapse-header {
  padding: 12px !important;
}
.payment-nav-root .ant-collapse-content {
  padding: 0px 12px 12px 12px !important;
  background-color: #fafafa;
}

.po-nav-logo-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.po-nav-title {
  color: var(--Neutral-Grey-1000);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.05px;
}

.po-nav-subtitle {
  color: var(--Neutral-Grey-1000);
}
