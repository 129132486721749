.upi-intent-root {
  display: flex;
  border-radius: 6px;
  background: var(--Neutral-2);
  padding: 8px 12px;
}

.upi-intent-content {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  gap: 11px;
  align-items: center;
}
.upi-intnet-title {
  color: #595e6a;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.577px;
}
