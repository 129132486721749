.domestic-logout-root {
  padding: 12px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid var(--Neutral-4, #f0f0f0);
  background: #fff;
  display: flex;
}
.domestic-guest-logout-root {
  padding: 12px 16px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 12px;
  border: 1px solid var(--Neutral-4, #f0f0f0);
  background: #fff;
  display: flex;
}
.domestic-mb-logout {
  display: flex;
  align-items: center;
  gap: 4px;
}
