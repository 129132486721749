.cvvDiv {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.cvvDiv .ant-form-item {
  width: 46%;
}

.buttonDiv {
  margin-top: 20px;
}

.defaultCardColor {
  color: rgba(173, 172, 176, 1);
  height: 20px;
  width: 20px;
}

.cardNumberField .ant-input-group-wrapper-outlined .ant-input-group-addon {
  background: none;
}

.cardNumberField .ant-input-group .ant-input {
  border-right: 1px solid transparent;
}

.checkboxText {
  color: var(--Global-Neutral-Grey-1000, #4f4d55);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.ant-tooltip-inner {
  font-size: 10px;
}