.upi-timer-modal-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.upi-timer-modal-header {
  display: flex;
  flex-direction: column;
  color: var(--Neutral-Grey-1100);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02px;
}

.upi-id-conatiner {
  margin-top: 14px;
  display: flex;
  padding: 6px 25.5px 7px 25.5px;
  justify-content: center;
  align-items: center;
  border-radius: 42px;
  border: 1px solid #bababa;
  background: #fff;
  gap: 4px;
}

.upi-timer-modal-timer {
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-top: 26px;
}

.modal-timer-expiry-font {
  color: var(--Neutral-Grey-1200);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 27.429px;
  letter-spacing: -0.069px;
}

.upi-timer-modal-footer {
  margin-top: 40px;
  color: var(--Neutral-Grey-1000);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 11px;
}

.timer-font {
  color: var(--Red-700, #f53535);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32.914px;
  letter-spacing: -0.357px;
}
