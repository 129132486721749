.app-root {
  min-height: 100vh;
  background-image: linear-gradient(
      204deg,
      rgba(0, 0, 0, 0) 17.36%,
      rgba(0, 0, 0, 0.84) 77.77%
    ),
    url("./assets/kutumb-bck-1.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  object-fit: contain;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
  text-decoration: none;
}
